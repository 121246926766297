<template>
  <div class="check">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="实训名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="渠道老师">
        <el-input v-model="form.qudao"></el-input>
      </el-form-item>
      <el-form-item label="实训情况">
        <el-input
          v-model="form.des"
          placeholder="学生专业-年级-学历"
        ></el-input>
      </el-form-item>
      <el-form-item label="实训讲师">
        <el-input
          v-model="form.jiangshi"
          placeholder="多个讲师用 / 分割"
        ></el-input>
      </el-form-item>
      <el-form-item label="实训人数">
        <el-input v-model="form.lilun" placeholder="理论实训人数"></el-input>
      </el-form-item>
      <el-form-item label="实训时长">
        <el-input
          v-model="form.dayNum"
          placeholder="实训具体的天数比如：5天，10天等"
        ></el-input>
      </el-form-item>
      <el-form-item label="实训时间">
        <el-col :span="11">
          <el-date-picker
            type="date"
            placeholder="开始时间"
            v-model="form.date"
            style="width: 100%"
          ></el-date-picker>
        </el-col>
        <el-col class="line" :span="1">--</el-col>
        <el-col :span="11">
          <el-date-picker
            type="date"
            placeholder="结束时间"
            v-model="form.endDate"
            style="width: 100%"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="实训方案">
        <el-input type="hidden" v-model="form.file"></el-input>
        <el-upload
          v-loading="loading"
          class="upload-demo"
          ref="upload"
          :action="url"
          :file-list="fileList"
          :auto-upload="false"
          :on-success="successAction"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            @click="submitUpload"
            >上传到服务器</el-button
          >
          <div slot="tip" class="el-upload__tip">
            只能上传excel文件，且不超过500kb
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即添加</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "ShixunAdd",
  data: function () {
    return {
      form: {
        name: null,
        qudao: null,
        jiangshi: null,
        lilun: null,
        date: null,
        endDate: null,
        dayNum: null,
        des: null,
        file: null,
      },
      fileList: [],
      url: "",
      loading: false,
    };
  },
  methods: {
    submitUpload() {
      //设置文件的名称
      this.url = this.$IP.IP2 + "/exam/files/2/";
      this.loading = true;
      setTimeout(() => {
        this.$refs.upload.submit();
      }, 10);
    },
    successAction(res) {
      if (res.code == 200) {
        this.form = { ...this.form, file: res.name };
        this.loading = false;
        this.$alert("已上传", "提示", {
          confirmButtonText: "确定",
        });
      }
    },
    //完成数据添加
    async onSubmit() {
      if (!this.form.file) {
        this.$alert("请上传实训方案", "提示", {
          confirmButtonText: "确定",
        });
        return 0;
      } else if (!this.form.date || !this.form.endDate) {
        this.$alert("请选择实训起止时间", "提示", {
          confirmButtonText: "确定",
        });
        return 0;
      }
      let date = this.getDate(this.form.date);
      let endDate = this.getDate(this.form.endDate);
      let form = { ...this.form, date, endDate };
      this.$datas.meta = { form };
      //开始网络请求
      let res = await this.$datas.sxAdd;
      if (res.status < 300 && res.status >= 200) {
        this.$alert("活动已添加", "提示", {
          confirmButtonText: "确定",
        });
      }
    },
    getDate(date) {
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      let day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style lang="less">
.check {
  width: 70%;
  padding: 30px 0;
}
</style>
